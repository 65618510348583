<template>
  <div class="container">
    <h1>Leaderboard</h1>
    <VWLeaderboard :leaders="leaders" />
    <div class="pagination">
      <VWPagination v-model="currentPage" :totalItems="totalRecords" :perPage="perPage" />
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import VWLeaderboard from '@/components/VWLeaderboard.vue';
import VWPagination from '@/components/VWPagination.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'Leaderboard',
  components: {
    VWLeaderboard,
    VWPagination,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      leaders: [],
      currentUser: null,
      totalRecords: 10,
    };
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      return this.startIndex + this.perPage;
    },
  },
  mounted() {
    this.getLeaderboardPage();
  },
  watch: {
    currentPage() {
      this.getLeaderboardPage();
    },
  },
  methods: {
    async getLeaderboardPage() {
      const { data } = await ApiService.get('/points/leaderboard', { offset: this.startIndex });
      this.leaders = data.leaderboard;
      this.totalRecords = data.total;
      this.currentUser = {
        place: this.$store.state.rank,
        username: this.$store.state.username,
        points: this.$store.state.points,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 40px;
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
.pagination {
  margin-top: 1em;
}
::v-deep .pagination-button {
  color: $primary-white;
}
::v-deep .vw-pagination {
  color: $primary-white;
}
</style>
