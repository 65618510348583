<template>
  <Paginate
    v-model="localValue"
    v-bind="{ ...$props, ...$attrs }"
    class="g-pagination"
    :container-class="'paginate'"
    :page-count="localPageCount"
    v-on="$listeners"
  >
    <template v-slot:prevButton>
      <Arrow class="arrow" />
    </template>
    <template v-slot:nextButton>
      <Arrow class="arrow right" />
    </template>
  </Paginate>
</template>

<script>
// TODO verify that the 2 way data binding is working
// TODO should allow for there to be a number larger than the page count
import Arrow from '@/assets/icons/arrow.svg';
import Paginate from './dependancies/pagination.vue';

/**
 * @version 0.0.1
 */
export default {
  name: 'GPagination',
  components: { Paginate, Arrow },
  inheritAttrs: false,
  extends: Paginate,
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    totalItems: Number,
    perPage: Number,
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', parseInt(newValue, 10));
    },
    value(newValue) {
      this.localValue = parseInt(newValue, 10);
    },
  },
  computed: {
    localPageCount() {
      return Math.ceil(this.totalItems / this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.g-pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  .arrow {
    display: block;
    height: 100%;
    max-height: 50px;
    cursor: pointer;
    &.right {
      transform: rotate(180deg);
      transform-origin: center;
    }
    .fill {
      fill: currentColor;
    }
  }

  ::v-deep {
    .pagination-button {
      cursor: pointer;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        .pagination-button {
          opacity: 0.25;
        }
      }
      &.active {
        .pagination-button {
          font-weight: bold;
        }
      }
      &.li-prev {
        margin-right: auto;
      }
      &.li-next {
        margin-left: auto;
      }
    }
  }
}
</style>
