<template>
  <component :is="'GPagination'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="vw-pagination">
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import GPagination from '@/components/GPagination.vue';

export default {
  name: 'VWPagination',
  inheritAttrs: false,
  extends: GPagination,
  components: {
    GPagination,
  },
  props: {
    nextButtonClass: {
      default: 'test',
    },
  },
};
</script>

<style lang="scss" scoped>
.vw-pagination {
  max-width: 450px;
  font-size: 14px;
  color: black;
  @include bp-sm-phone-landscape {
    width: 450px;
  }

  ::v-deep {
    .arrow {
      width: 12px;
      line-height: 30px;
    }

    .pagination-button {
      padding: 5px;
      background: none;
      border: none;
    }

    li {
      min-width: 50px;
      &.disabled {
        .pagination-button {
          opacity: 0.25;
        }
      }
      &.active {
        .pagination-button {
          font-weight: bold;
        }
      }
      &.li-prev {
        margin-right: auto;
      }
      &.li-next {
        margin-left: auto;
      }
    }
  }
  //
}
</style>
