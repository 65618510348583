<template>
  <table class="vw-leaderboard">
    <thead class="leaderboard-header">
      <tr>
        <th>Place</th>
        <th>Username</th>
        <th>Points</th>
      </tr>
    </thead>
    <tbody class="leaderboard-body">
      <tr v-for="(user, index) in leaders" :key="index" :class="{ isCurrentUser: isCurrentUser(user.place) }">
        <td class="place">{{ user.place }}</td>
        <td class="name">{{ user.username }}</td>
        <td class="points-leaderboard">{{ user.points }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'VWLeaderboard',
  components: {},
  props: {
    leaders: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    ...mapState(['rank']),
  },
  methods: {
    isCurrentUser(place) {
      return place === this.rank;
    },
  },
};
</script>

<style lang="scss" scoped>
.vw-leaderboard {
  width: 80%;
  font-size: 10px;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba($primary-black, 0.16);
  thead {
    th {
      padding: 9px 15px;
      font-family: $head-font-stack;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }
  }
  .leaderboard-body {
    tr {
      border-spacing: 0 15px;
      // font-family: $head-font-stack;
      &:first-of-type {
        border: none;
      }
      &.isNotRanked,
      &.isCurrentUser {
        background-color: $light-grey;
      }
      &.break-wrapper {
        height: 0;
        border: none;
        td {
          padding: 0;
        }
      }
      td {
        padding: 15px;
      }
      .place {
        width: calc((100 / 1024) * 100%);
        font-size: 28px;
        text-align: left;
        @include bp-md-tablet {
          font-size: 2.8em;
        }
      }
      .name {
        padding-left: 20px;
        font-size: 18px;
        text-align: left;
      }
      .points-leaderboard {
        width: calc((120 / 1024) * 100%);
        font-size: 18px;
        text-align: right;
        @include bp-md-tablet {
          font-size: 2.8em;
        }
      }
    }
  }
}
</style>
